<template>
  <div class="overflow-hidden">
    <Content @handleGet="handleGet" />
    <Modal v-if="isOpen" @close="isOpen = false">
      <!-- <div class="">
        <img src="./assets/qrcodeIos.svg" class="w-52 h-52" />

        <p class="text-center text-white">Scan to Download</p>
      </div> -->
      <div className="text-white text-3xl mt-10">Coming Soon</div>
    </Modal>
  </div>
</template>

<script setup>
import { ref } from "vue";
import Modal from "./components/Modal.vue";
import Content from "./components/Content.vue";
const isOpen = ref(false);

const handleGet = () => {
  // isOpen.value = true;
  const isIOS =
    (/iPad|iPhone|iPod/.test(navigator.userAgent) ||
      navigator.userAgent.indexOf("Apple") != -1) &&
    !window.MSStream;
  const isAndroid =
    /Android/.test(navigator.userAgent) || navigator.userAgent.indexOf("Win");

  // Define the URLs for the App Store and Play Store
  const appStoreUrl = "https://apps.apple.com/ng/app/worknprosper/id6451487138";
  const playStoreUrl = "https://i.diawi.com/FahWPe";

  // Open the respective store based on the user's platform
  if (isIOS) {
    window.location.href = appStoreUrl;
  } else if (isAndroid) {
    downloadApk();
  } else {
    // Handle other platforms if needed
    downloadApk();

    console.warn("Unsupported platform");
  }
  function downloadApk() {
    const playStoreUrl =
      "https://play.google.com/store/apps/details?id=com.myeazipay.employee&hl=en&gl=US";

    window.location.href = playStoreUrl;

    // console.log("downloading");
    // let host = window.location.protocol + "//" + window.location.host;
    // axios({
    //   url: `${host}/uploads/worknProsper.apk`,
    //   method: "GET",
    //   responseType: "blob",
    // }).then((response) => {
    //   var fileURL = window.URL.createObjectURL(new Blob([response.data]));
    //   var fileLink = document.createElement("a");
    //   fileLink.href = fileURL;
    //   fileLink.setAttribute("download", `worknProsper.apk`);
    //   document.body.appendChild(fileLink);
    //   fileLink.click();
    // });
  }
};
</script>

<style lang="scss" scoped>
@import url("../../styles/www.scss");

.hero {
  .heading {
    color: #292a29;
  }
}

.freedom-section {
  background: #f8f8f8;
  .heading {
    font-family: "Roboto";
    span {
      color: #ea4e4b;
    }
  }
}

.what-we-offer-section {
  .heading {
    font-family: "Roboto";
  }
}
</style>
