<template>
    <div
      class="bg-lighterGrey hover:bg-secondary-new  p-6 relative rounded-4xl"
      @mouseover="toggleHover(true)"
      @mouseleave="toggleHover(false)"
    >
      <div
      >
        <img :src="IconSrc" alt="Card Image"  />
      </div>
  
      <div class="mt-3 flex flex-col gap-3">
        <div class=" text-primary-deep font-bold text-2xl">
          {{ title }}
        </div>
        <div class="text-grey  font-normal text-lg">
          {{ description }}
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref } from "vue";
  defineProps({
      IconSrc: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
  });
  const isHovered = ref(false);
  
  import firstImage from "../pages/mgt/assets/inActiveState.svg";
  import secondImage from "../pages/mgt/assets/activeState.svg";
  
  const toggleHover = (value) => {
    isHovered.value = value;
  };
  </script>
  
  <style scoped></style>
  